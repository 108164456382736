<template>
    <div class="page-container">
        <div class="content-container">
            <div class="input-wrapper">
                <Accordion @click="accordionClicked(CONNECTION_PANEL)" class="input-container round-container" :ref="CONNECTION_PANEL" :open="openpanel === CONNECTION_PANEL" :active="activepanels.includes(CONNECTION_PANEL)" :title="$t('product-details.panels.connection')">
                    <div class="panel-container">
                        <span class="input full additional-info" v-if="orderingprocesstext" v-html="orderingprocesstext"></span>

                        <span class="input full additional-info" v-if="additionalinfo" v-html="additionalinfo"></span>

                        <span class="input full additional-info" v-if="alternativetext" v-html="alternativetext"></span>

                        <div class="input full header" v-if="withlocations || withzones">
                            <label>{{ $t('product-details.start-end') }}</label>
                        </div>

                        <AutoCompleteInput idstub="dm9u" v-if="withlocations" class="input" :label="$t('general.from')" v-model="from" :completemethod="searchStartPoint" @select="fromSelected"/>
                        <AutoCompleteInput idstub="bmFjaA==" v-if="withlocations" class="input" :label="$t('general.to')" v-model="to" :completemethod="searchEndPoint" @select="toSelected"/>

                        <DropdownInput idstub="dm9uem9uZQ==" v-if="withzones" class="input" :defaultlabel="$t('general.please-select')" :label="$t('general.from-zone')" :defaultoption="true"/>
                        <DropdownInput idstub="bmFjaHpvbmU=" v-if="withzones" class="input" :defaultlabel="$t('general.please-select')" :label="$t('general.to-zone')" :defaultoption="true"/>

                        <div class="input full header" v-if="withwholenet || withadditionalzones">
                            <label>{{ $t('general.zones') }}</label>
                        </div>

                        <DropdownInput idstub="d2FobHdlZw==" v-show="tripchoices.length > 0" class="input" :value="this.productrelation && this.productrelation.IDRelation?this.productrelation.IDRelation:-1" @input="selectRelation" :optionlist="tripchoices" :defaultoption="true" :defaultlabel="$t('general.please-select')" :label="$t('general.choice-of-way')" />
                        <CheckboxInput idstub="Z2VzYW10bmV0eg==" v-show="withwholenet" class="input" inputid="whole-net" v-model="product.wholenet" :text="$t('general.whole-net')" @input="getRelation(true)"/>

                        <MultiSelectInput idstub="enVzYXR6em9uZW4=" v-if="withadditionalzones" :options="availablezones" class="input full" :label="$t('general.additional-zones')" v-model="product.additionalzones" @input="getRelation(true)"/>

                        <div class="input full header" v-if="firstclassoption">
                            <label>{{ $t('general.first-class-surcharge') }}</label>
                        </div>

                        <CheckboxInput idstub="ZXJzdGVrbGFzc2U=" v-if="firstclassoption" class="input" inputid="first-class-surcharge" v-model="product.additionalcharge" :text="$t('general.first-class-surcharge')" @input="getRelation(true)"/>

                        <div class="next-container input full">
                            <button id="connection-next" class="passive" @click="nextPanel(CONNECTION_PANEL)" :disabled="!connectiondone">
                                <label>{{ $t('general.next') }}</label>
                                <v-icon name="arrow-down"/>
                            </button>
                        </div>
                    </div>
                </Accordion>

                <Accordion @click="accordionClicked(VALIDITY_PERIOD_PANEL)" class="input-container round-container" :ref="VALIDITY_PERIOD_PANEL" :open="openpanel === VALIDITY_PERIOD_PANEL" :active="activepanels.includes(VALIDITY_PERIOD_PANEL)" :title="$t('product-details.panels.validity')">
                    <div class="panel-container">
                        <div class="input full header">
                            <label>{{ $t('general.start-of-validity') }}</label>
                        </div>

                        <span class="input full additional-info" v-html="this.validityhint"></span>

                        <!-- TODO: needed? -->
                        <!-- <DateInput class="input" label="Gültigkeitsbeginn" v-model="product.startdate" /> -->

                        <!-- v-if="!isdayspecific && !existingContract" -->
                        <SelectOneInput idstub="Z3VlbHRpZ2tlaXQ=" v-if="!isdayspecific && true" class="input full" :value="product.validity.displayid" @input="event => product.validity = event" :options="validityoptions"/>
                        <!--
                            old: new contract/switch contract === different date inputs
                            => I don't think we need this distinction
                        -->
                        <!-- <DropdownInput v-if="!isdayspecific && false" class="input" :defaultlabel="$t('general.please-select')" label="Gueltigkeitsbeginn Vertrag" :defaultoption="true"/> -->

                        <DateInput idstub="c3RhcnR0YWc=" v-if="isdayspecific" class="input" :label="$t('general.day-specific-start-of-validity')" v-model="product.startday"/>

                        <div class="next-container input full">
                            <button id="validity-next" :class="[ withcertificates || withcustomerprofiles ? 'passive' : 'active']" @click="nextPanel(VALIDITY_PERIOD_PANEL)">
                                <label>{{ withcertificates || withcustomerprofiles ? $t('general.next') : $t('general.done') }}</label>
                                <v-icon :name="withcertificates || withcustomerprofiles ? 'arrow-down' : 'check'"/>
                            </button>
                        </div>
                    </div>
                </Accordion>

                <Accordion v-if="withcertificates" @click="accordionClicked(DOCUMENTS_PANEL)" class="input-container round-container" :ref="DOCUMENTS_PANEL" :open="openpanel === DOCUMENTS_PANEL" :active="activepanels.includes(DOCUMENTS_PANEL)" :title="$t('general.documents')">
                    <div class="panel-container flex-flow-column">
<!--                         <div class="input full header">
                            <label>Nachweise hochladen</label>
                        </div> -->

                        <div class="input full">
                            <label class="upload-info">{{ $t('product-details.upload-hint-1') }}</label>
                            <p class="certificate-info" v-for="(cert, idx) in product.original.Bescheinigungen.TOWSBescheinigung" :key="cert.IDBescheinigung">
                                <b>{{ `${idx+1}. ${cert.Bezeichnung}: ` }}</b><b v-if="cert.Optional">({{ $t('general.optional') }})</b><br /><span class="certificate-text">{{ cert.Beschreibung.Text }}</span>
                            </p>
                        </div>

                        <FileUpload :limit="product.original.Bescheinigungen.TOWSBescheinigung.length" v-model="this.product.uploads" acceptedFileTypes="image/jpeg,image/bmp,image/png,application/pdf" :sizeLimitMb="5"/>
                        <label class="upload-hint">{{ $t('product-details.upload-hint-2') }}</label>

                        <div class="next-container input full">
                            <button id="documents-next" :class="[ withcertificates || withcustomerprofiles ? 'passive' : 'active']" @click="nextPanel(DOCUMENTS_PANEL)" :disabled="!(this.product.uploads.length >= this.neededDocuments && this.product.uploads.length <= this.product.original.Bescheinigungen.TOWSBescheinigung.length)">
                                <label>{{ withcustomerprofiles ? $t('general.next') : $t('general.done') }}</label>
                                <v-icon :name="withcustomerprofiles ? 'arrow-down' : 'check'"/>
                            </button>
                        </div>
                    </div>
                </Accordion>

                <Accordion v-if="withcustomerprofiles" @click="accordionClicked(CUSTOMERPROFILES_PANEL)" class="input-container round-container" :ref="CUSTOMERPROFILES_PANEL" :open="openpanel === CUSTOMERPROFILES_PANEL" :active="activepanels.includes(CUSTOMERPROFILES_PANEL)" :title="$t('general.customer-profile')">
                    <div class="panel-container flex-flow-column">

                        <DropdownInput idstub="a3VuZGVucHJvZmls" class="input" :defaultlabel="$t('general.please-select')" :label="$t('general.customer-profile')" :value="this.product.customerprofile.displayid" @input="event => product.customerprofile = event" :optionlist="customerprofileoptions" :required="true" :defaultoption="true"/>

                        <div class="next-container input full">
                            <button id="profiles-next" class="active" @click="nextPanel(CUSTOMERPROFILES_PANEL)" :disabled="!this.done">
                                <label>{{ $t('general.done') }}</label>
                                <v-icon name="check"/>
                            </button>
                        </div><div class="next-container input full">
                            
                        </div>
                    </div>
                </Accordion>
            </div>

            <div class="information-wrapper">
                <TicketInformationContainer :product="this.product" :relation="this.productrelation"/>

                <button id="general-next" class="active complete-container" @click="goToPersonalData()" :disabled="!done">
                    <label>{{ $t('general.next') }}</label>
                    <label>{{ $t('product-details.proceed-to-personal') }}</label>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

import DateInput from '../components/systems/DateInput.vue'
import TicketInformationContainer from '../components/systems/TicketInformationContainer.vue'
import CheckboxInput from '../components/systems/elements/CheckboxInput.vue'
import Accordion from '../components/systems/elements/Accordion.vue'
import FileUpload from '../components/systems/FileUpload.vue'
import DropdownInput from '../components/systems/elements/DropdownInput.vue'
import MultiSelectInput from '../components/systems/elements/MultiSelectInput.vue'

import 'vue-awesome/icons/arrow-right'
import 'vue-awesome/icons/check'
import 'vue-awesome/icons/redo'

import SelectOneInput from '../components/systems/elements/SelectOneInput.vue'
import AutoCompleteInput from '../components/systems/AutoCompleteInput.vue'

export default {
    name: 'ProductDetailsPage',
    data() {
        return {
            openpanel: undefined,
            activepanels: [],
            tripchoices: [],
            availablezones: [],
            validityoptions: this.$root.$data.product.original.Startdaten.Data?.dateTime?.map((entry, idx) => {
                return {
                    displayid: idx,
                    displayname: this._formatDate(new Date(entry)),
                    original: entry
                }
            }),
            prepaid: { displayid:-1, displayname:'' },
            prepaidoptions: this.$root.$data.systemconfiguration.anlageassistent?.prepaidoptions?.map(option => {
                return { displayid: option, displayname: option + ' Euro' }
            }),
            from: '',
            to: '',
            customerprofileoptions: this.$root.$data.product.original.Kundenprofile?.TOWSKundentypDaten?.map((entry, idx) => {
                return {
                    displayid: idx,
                    displayname: entry.KundentypBezeichnung,
                }
            }),
        }
    },
    computed: {
        systemconfiguration() {
            return this.$root.$data.systemconfiguration
        },
        connectiondone() {
            // if we don't need the locations, theres also no need to check them
            const locationsdone = !this.withlocations || (!!this.product.from && !!this.product.to && !!this.productrelation)
            
            let done = locationsdone

            // if the tariff mode is withadditionalzones, the locations are optional
            if (this.withadditionalzones) {
                done = done || this.product.additionalzones?.length > 0
            }

            // if wholenet ist selected, we don't need to enter locations
            if (this.product.wholenet) {
                done = true;
            }

            return done
        },
        validitydone() {
            return true // there is always at least one element selected
        },
        neededDocuments() {
            let neededDocuments = this.product.original.Bescheinigungen?.TOWSBescheinigung.length
                this.product.original.Bescheinigungen.TOWSBescheinigung.forEach(document => {
                    if (document.Optional) {
                        neededDocuments--
                    }
                })
                return neededDocuments
        },
        documentsdone() {
            if (!this.product.original.Bescheinigungen?.TOWSBescheinigung) {
                return true
            } else {
                return this.product.uploads.length >= this.neededDocuments && this.product.uploads.length <= this.product.original.Bescheinigungen?.TOWSBescheinigung.length
            }
        },
        customerprofilesdone() {
            if (!this.withcustomerprofiles) {
                return true
            } else {
                return this.product.customerprofile?.displayname && this.product.customerprofile?.displayid > -1
            }
        },
        readall() {
            return this.activepanels.includes(this.LAST_PANEL)
        },
        done() {
            return this.connectiondone && this.validitydone && this.documentsdone && this.customerprofilesdone && this.readall
        },
        additionalinfo() {
            // 7 === PRODUKT
            return this.getText(7)
        },
        orderingprocesstext() {
            // 6 === BESTELLPROZESS
            return this.getText(6)
        },
        alternativetext() {
            // 18 === PRODUKT_INFO_ALTERMATIVTEXT
            var result = undefined
            if (this.product.original.Bescheinigungen
                    && this.product.original.Bescheinigungen.TOWSBescheinigung) {
                const text = this.product.original.Bescheinigungen.TOWSBescheinigung.find(entry => entry.Variante === 18)
                if (text) result = text.Text
            }
            return result
        },
        validityhint() {
            return this.product.original.Startdaten.Info
        },
        isafb() {
            return this.product.original.TarifArt.toUpperCase() === 'AFB'
        },
        withcertificates() {
            return this.product.original.Bescheinigungen
                    && this.product.original.Bescheinigungen.TOWSBescheinigung
                    && this.product.original.Bescheinigungen.TOWSBescheinigung.length > 0
        },
        withcustomerprofiles() {
            return this.product.original.Kundenprofile
            && this.product.original.Kundenprofile.TOWSKundentypDaten
        },
        product: {
            get() {
                return this.$root.$data.product
            },
            set(value) {
                this.$root.$data.product = value
            }
        },
        withlocations() {
            return [
                0, // UNDEF
                1, // TARIFIERT
                6, // RELATIONSFREI_MIT_ORTSWAHL
                8, // TARIFIERT_MIT_ZUSATZZONEN
                3, // ZONEN
            ].includes(this.product.original.Tarifmodus)
        },
        withzones() {
            return [
                3, // ZONEN
            ].includes(this.product.original.Tarifmodus)
        },
        withadditionalzones() {
            return [
                0, // UNDEF
                8, // TARIFIERT_MIT_ZUSATZZONEN
            ].includes(this.product.original.Tarifmodus)
        },
        withwholenet() {
            return this.product.original.ModusGesamtNetz !== 0 // 0 === ohne
        },
        isdayspecific() {
            return this.product.original.Tagesscharf
        },
        firstclassoption() {
            return this.product.original.FirstClassActive
        },
        productrelation: {
            get() {
                return this.$root.$data.relation
            },
            set(value) {
                this.$root.$data.relation = value
            }
        }
    },
    created() {
        // "const" declarations
        this.CONNECTION_PANEL      = 0
        this.VALIDITY_PERIOD_PANEL = 1
        this.DOCUMENTS_PANEL       = 2
        this.CUSTOMERPROFILES_PANEL = this.withcertificates ? 3 : 2

        if (this.withcustomerprofiles) {
            this.LAST_PANEL = this.CUSTOMERPROFILES_PANEL
        } else if (this.withcertificates) {
            this.LAST_PANEL = this.DOCUMENTS_PANEL
        } else {
            this.LAST_PANEL = this.VALIDITY_PERIOD_PANEL
        }

        this.openpanel = this.CONNECTION_PANEL
        this.activepanels.push(this.openpanel)

        // prepare product cache
        if (!this.product.from && !this.product.to) {
            this.product = {
                ...this.product,
                from: '',
                to: '',
                additionalcharge: false,
                startday: new Date(),
                variant: 'Persönlich',
                wholenet: false,
                validity: {displayid:-1, displayname:''},
                additionalzones: undefined,
                uploads: [],
                customerprofile: {displayid:-1, displayname:''}
            }
        }

        if (this.productrelation && this.productrelation.prepaid) {
            this.prepaid = this.productrelation.prepaid
        }

        if (this.product.to && this.product.from) {
            this.from = this.product.from.TarifstellenOrtText
            this.to = this.product.to.TarifstellenOrtText
            this.getRelation(true)
        }
    },
    mounted: async function() {
        this.product.validity = this.validityoptions?.at(0)

        await this.initAdditionalZones()

        if (this.product.original.Tarifmodus === 5) { // 5 === ABO
            this.getRelation(true)
        }
    },
    methods: {
        initAdditionalZones() {
            let url = Vue.middleware()
            url += '/basedata/zones'

            axios.get(url)
            .then(response => {
                this.availablezones = response.data.data?.map(zone => {
                    return {
                        displayid: zone.ID_TarifstellenOrt,
                        displayname: zone.TarifstellenOrtText,
                        original: zone
                    }
                })
            })
            .catch(err => {
                console.log(err)
            })
        },
        selectRelation(event) {
            this.productrelation = event.original
        },
        getText(variant) {
            var result = undefined
            if (this.product.original.Infotexte
                    && this.product.original.Infotexte.TOWSInfotext) {
                const text = this.product.original.Infotexte.TOWSInfotext.find(entry => entry.Variante === variant)
                if (text) result = text.Text
            }
            return result
        },
        searchStartPoint(input) {
            this.relation = undefined

            if (!input) return new Promise(resolve => {resolve([])})

            let url = Vue.middleware()
            url += `/products/${this.product.original.ProduktID}`
            url += `/startpoints?q=${input}`

            return axios.get(url)
            .then(response => {
                const todata = response.data

                if (todata.status.ErrorCode !== 0) {
                    // TODO:
                }

                return todata.data.startpointlist.map(entry => {
                    return {
                        displayid: entry.ID_TarifstellenOrt,
                        displayname: entry.TarifstellenOrtText,
                        original: entry
                    }
                })
            })
        },
        searchEndPoint(input) {
            this.relation = undefined

            if (!input) return new Promise(resolve => {resolve([])})

            let url = Vue.middleware()
            url += `/products/${this.product.original.ProduktID}`
            url += `` // TODO: TarifstellennummerStartpunkt
            url += `/endpoints?q=${input}`

            return axios.get(url)
            .then(response => {
                const todata = response.data

                if (todata.status.ErrorCode !== 0) {
                    // TODO:
                }

                return todata.data.endpointlist.map(entry => {
                    return {
                        displayid: entry.ID_TarifstellenOrt,
                        displayname: entry.TarifstellenOrtText,
                        original: entry
                    }
                })
            })
        },
        getRelation: async function(fix) {
            this.$root.$data.loading = true

            const idrelationbefore = this.productrelation?.IDRelation

            this.productrelation = undefined
            this.tripchoices = []

            let url = Vue.middleware()
            url += '/clients/' + this.$root.$data.session.clientid
            url += '/products/' + this.product.id
            url += '/relations'

            let body = {
                product: this.product.original,
                activefrom: this.product.validity.original,
                wholenet: this.product.wholenet,
                firstclass: this.product.additionalcharge,
                from: this.product?.from || undefined,
                to: this.product?.to || undefined,
                zones: this.product?.additionalzones?.map(zones => zones.original)
            }

            return axios.post(url, body)
            .then(response => {

                if (response.data.status.ErrorCode !== 0) {
                    // TODO: toast? vuelidate?
                } else if (response.data.data === null) {
                    Vue.$toast.error(this.$t('product-details.no-relation'))
                    this.$root.$data.loading = false
                    return
                }

                const relations = response.data.data

                // if there is more than one relation
                // we need to show "wahlweg"
                if (relations.length === 1) {
                    this.productrelation = relations[0]
                } else {
                    if (relations) {
                        this.tripchoices = relations.map(entry => {
                            return {
                                displayid: entry.IDRelation,
                                displayname: entry.Relationsanzeigetext,
                                original: entry
                            }
                        })

                        this.productrelation = relations.find(relation => relation.IDRelation === idrelationbefore)

                        // if there was a selected relation, but it couldn't be found in the new result
                        // navigate to the first accordion again and let the user select someting else
                        if (idrelationbefore && !this.productrelation) {
                            this.activepanels = [this.CONNECTION_PANEL]
                            this.openpanel    = this.CONNECTION_PANEL
                            this.$refs[this.CONNECTION_PANEL].$refs.header.click()
                        }
                    }
                }

                this.$root.$data.loading = false
            })
            .then(() => {
                // "click" the header of the connection panel to fix content height
                // this must be triggered AFTER the tripchoices are rendered
                // -> therefore in next ".then()"
                if (fix) this.$refs[this.CONNECTION_PANEL].$refs.header.click()
                return undefined
            })
            .catch(err => {
                // TODO: toast? vuelidate?
                this.$root.$data.loading = false
                console.log(err)
            })
        },
        fromSelected(event) {
            if (event) {
                this.product.from = event.original
            } else {
                this.product.from = event
            }

            this.getRelation(true)
        },
        toSelected(event) {
            if (event) {
                this.product.to = event.original
            } else {
                this.product.to = event
            }

            this.getRelation(true)
        },
        handleZoneSelection(event) {
            // TODO:
            console.log(event)
        },
        goToPersonalData() {
            if (this.productrelation && this.productrelation.prepaid && this.productrelation.prepaid.displayid !== -1) {
                this.productrelation.prepaid = this.prepaid
            }
            this.$router.push({ name: 'personal-data' })
        },
        nextPanel(current) {
            // if there are hidden panels, we need to skip them
            const next = this.getNextPanelRef(current, this.LAST_PANEL)

            this.openpanel = next

            if (!this.activepanels.includes(next)) this.activepanels.push(next)

            // close current and open next
            if (this.$refs[current]) this.$refs[current].toggle()
            if (this.$refs[next]) this.$refs[next].toggle()
        },
        getNextPanelRef(current, lastpanel) {
            if (this.$refs[current + 1] !== undefined || current === lastpanel) {
                return current + 1
            } else {
                return this.getNextPanelRef(current + 1)
            }
        },
        accordionClicked(panel) {
            // close all accordions but the clicked one
            Object.keys(this.$refs).forEach(key => {
                const entry = this.$refs[key]
                if (key === panel || (entry && entry.open)) {
                    entry.toggle()
                }
            })

            this.openpanel = panel
        },
    },
    watch: {
        'product.validity'() {
            this.getRelation()
        },
        'product.startday'() {
            this.getRelation()
        }
    },
    components: {
        TicketInformationContainer,
        DateInput,
        CheckboxInput,
        Accordion,
        FileUpload,
        DropdownInput,
        MultiSelectInput,
        SelectOneInput,
        AutoCompleteInput
    }
}
</script>

<style scoped>
.content-container {
    display: flex;
    flex-wrap: wrap;
}

.content-container > .input-wrapper {
    flex: 1 1 100%;
}

.input-wrapper > .input-container {
    background: #fff;
}

.input-wrapper > .input-container:not(:first-child) {
    margin-top: 2em;
}

.input.header {
    text-align: left;
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
}

.content-container > .information-wrapper {
    min-width: 300px;
    width: 100%;
    margin-top: 2em;
    user-select: none;
}

.information-wrapper > .complete-container {
    flex-direction: column;
    margin-top: 20px;
    height: 75px;
    width: 100%;
    margin-left: 0;
}

.complete-container > label {
    display: block;
}

.complete-container > label:nth-of-type(1) {
    font-weight: bold;
    font-size: 1.5em;
}

.panel-container.flex-flow-column {
    flex-flow: column;
}

.panel-container > .next-container {
    padding: 15px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.panel-container > .additional-info {
    text-align: left;
    display: block;
}

.input.reset {
    border-radius: 8px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background-color: #ddd;
    margin-bottom: 30px;
    padding: 0 10px;
}

.input.reset:hover {
    background: #dddddd66;
}

.input.reset > svg {
    fill: red;
    margin-left: 10px;
    transform: scaleX(-1);
}

.upload-info {
    font-weight: bold;
    text-align: left;
    width: 100%;
    display: block;
}

.upload-hint {
    margin-top: 5px;
    text-align: left;
}

.certificate-info {
    text-align: left
}

.certificate-info > .certificate-text {
    padding-left: 30px;
    display: block;
}

@media(min-width: 576px) {
.panel-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: row;
    }

    .panel-container > .input {
        width: 49%;
    }

    .panel-container > .input.full {
        width: 100%;
    }
}

@media(min-width: 992px) {
    .content-container > .input-wrapper {
        flex: 1;
    }

    .content-container > .information-wrapper {
        min-width: 300px;
        width: 300px;
        margin-top: 0;
        margin-left: 4em;
    }
}
</style>
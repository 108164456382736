<template>
    <div class="page-container">
        <div class="content-container">
            <div class="input-wrapper">
                <Accordion @click="accordionClicked(PERSONAL_PANEL)" class="input-container round-container" :ref="PERSONAL_PANEL" :open="openpanel === PERSONAL_PANEL" :active="activepanels.includes(PERSONAL_PANEL)" :title="$t('personal-data.panels.personal-data')">
                    <div class="panel-container">
<!--                         <div class="input full header">
                            <label>Login Daten</label>
                        </div> -->

                        <TextInput idstub="ZW1haWw=" :disabled="this.$root.$data.isexistingcustomer" class="input" :autocomplete="'email'" :error="$v.uicustomer.email.$error || $v.uicustomer.createaccount.$error" :errortext="mailalreadyinusetext" v-model.trim="uicustomer.email" :label="$t('general.email')" :required="$v.uicustomer.email.$params.required !== undefined"/>
                        <TextInput idstub="ZW1haWxjb25maXJt" v-if="!this.$root.$data.isexistingcustomer" class="input" :error="$v.uicustomer.confirmemail.$error" v-model.trim="uicustomer.confirmemail" :label="$t('general.email-confirm')" :required="$v.uicustomer.confirmemail.$params.required !== undefined"/>

                        <CheckboxInput idstub="b25saW5lenVnYW5n" class="input" v-if="allowaccountcreation" v-model="uicustomer.createaccount" :required="$v.uicustomer.createaccount.required" text="Onlinezugang anlegen" @input="resetCreateaccount"/>
                        <span class="input" v-if="allowaccountcreation"/>

                        <!-- <span class="input full additional-info" v-html="accounthint"></span> -->

                        <div class="input full header">
                            <label>{{ $t('general.personal-data') }}</label>
                        </div>

                        <DropdownInput idstub="YW5yZWRl" :disabled="customerHasData.salutation" class="input" :error="$v.uicustomer.salutation.$error" :defaultlabel="$t('general.please-select')" :label="$t('general.salutation')" :value="uicustomer.salutation.displayid" @input="event => uicustomer.salutation = event" :optionlist="salutations" :required="$v.uicustomer.salutation.$params.required !== undefined" :defaultoption="true"/>
                        <DropdownInput idstub="dGl0ZWw=" :disabled="customerHasData.title" class="input" :error="$v.uicustomer.title.$error" :defaultlabel="$t('general.please-select')" :label="$t('general.title')" :value="uicustomer.title.displayid" @input="event => uicustomer.title = event" :optionlist="titles" :required="$v.uicustomer.title.$params.required !== undefined" :defaultoption="true"/>

                        <TextInput idstub="dm9ybmFtZQ==" :disabled="customerHasData.firstname" class="input" :autocomplete="'given-name'" :error="$v.uicustomer.firstname.$error" v-model="uicustomer.firstname" :label="$t('general.firstname')" :required="$v.uicustomer.firstname.$params.required !== undefined"/>
                        <TextInput idstub="bmFjaG5hbWU=" :disabled="customerHasData.lastname" class="input" :autocomplete="'family-name'" :error="$v.uicustomer.lastname.$error" v-model="uicustomer.lastname" :label="$t('general.lastname')" :required="$v.uicustomer.lastname.$params.required !== undefined"/>

                        <DateInput idstub="Z2VidXJ0c3RhZw==" :disabled="customerHasData.birthdate" class="input" :error="$v.uicustomer.birthdate.$error" :errortext="customerageerror" v-model="uicustomer.birthdate" :label="$t('general.birthdate')" :required="$v.uicustomer.birthdate.$params.required !== undefined" :formatDate="_formatDate"/>
                        <TextInput idstub="dGVsZWZvbg==" :disabled="customerHasData.phone" class="input" :autocomplete="'tel'" :error="$v.uicustomer.phone.$error" v-model="uicustomer.phone" :required="$v.uicustomer.phone.$params.required !== undefined" :label="$t('general.phone-at-day')" />

                        <div class="next-container input full">
                            <button id="personal-next" class="passive" @click="personalDataNext">
                                <label>{{ $t('general.next') }}</label>
                                <v-icon name="arrow-down"/>
                            </button>
                        </div>
                    </div>
                </Accordion>

                <Accordion @click="accordionClicked(ADDRESS_PANEL)" class="input-container round-container" :ref="ADDRESS_PANEL" :open="openpanel === ADDRESS_PANEL" :active="activepanels.includes(ADDRESS_PANEL)" :title="$t('personal-data.panels.address-data')">
                    <div class="panel-container">
                        <div class="input full header">
                            <label>{{ $t('general.address')}}</label>
                        </div>

                        <TextInput idstub="c3RyYXNzZQ==" :disabled="customerHasData.street" class="input" :autocomplete="'address-line1'" :error="$v.uicustomer.street.$error" v-model="uicustomer.street" :label="$t('general.street')" :required="$v.uicustomer.street.$params.required !== undefined"/>
                        <TextInput idstub="aGF1c25y" :disabled="customerHasData.housenr" class="input" :autocomplete="'address-line2'" :error="$v.uicustomer.housenr.$error" v-model="uicustomer.housenr" :label="$t('general.house-nr')" :required="$v.uicustomer.housenr.$params.required !== undefined"/>

                        <TextInput idstub="aGF1c25yenVzYXR6" :disabled="customerHasData.addressinfo" class="input" :autocomplete="'address-line3'" :error="$v.uicustomer.addressinfo.$error" v-model="uicustomer.addressinfo" :required="$v.uicustomer.addressinfo.$params.required !== undefined" :label="$t('general.address-addition')" />
                        <span class="input" />

                        <TextInput idstub="cGx6" class="input" :disabled="customerHasData.zip" :autocomplete="'postal-code'" :error="$v.uicustomer.zip.$error" v-model="uicustomer.zip" :label="$t('general.zip')" @input="getCity('uicustomer', $event)" :required="$v.uicustomer.zip.$params.required !== undefined"/>
                        <TextInput idstub="b3J0" class="input" :disabled="customerHasData.city" :autocomplete="'address-level2'" :error="$v.uicustomer.city.$error" v-model="uicustomer.city" :label="$t('general.city')" :required="$v.uicustomer.city.$params.required !== undefined"/>

                        <TextInput idstub="b3J0c3RlaWw=" class="input" :autocomplete="'address-level3'" :error="$v.uicustomer.district.$error" v-model="uicustomer.district" :required="$v.uicustomer.district.$params.required !== undefined" :label="$t('general.district')"/>
                        <DropdownInput idstub="bGFuZA==" :disabled="customerHasData.country" class="input" :defaultlabel="$t('general.please-select')" :label="$t('general.country')" :error="$v.uicustomer.country.$error" :value="uicustomer.country.displayid" @input="event => uicustomer.country = event" :required="$v.uicustomer.country.$params.required !== undefined" :optionlist="countries" />

                        <div class="input full header" v-if="this.selectedproduct.original.AbweichenderKarteninhaber">
                            <label>{{ $t('personal-data.different-card-owner') }}</label>
                        </div>
                        <CheckboxInput idstub="YWJ3ZWljaGVuZGVyZmtp" v-if="this.selectedproduct.original.AbweichenderKarteninhaber" class="input" :text="$t('personal-data.different-card-owner')" v-model="uicustomer.differentcardowner" :required="$v.uicustomer.differentcardowner.$params.required !== undefined"/>

                        <div class="next-container input full">
                            <button id="address-next" :class="[ this.openpanel !== this.LAST_PANEL ? 'passive' : 'active']" @click="adressDataNext">
                                <label>{{ this.openpanel !== this.LAST_PANEL ? $t('general.next') : $t('general.done') }}</label>
                                <v-icon :name="this.openpanel !== this.LAST_PANEL ? 'arrow-down' : 'check'"/>
                            </button>
                        </div>
                    </div>
                </Accordion>

                <Accordion v-if="uicustomer.differentcardowner" @click="accordionClicked(DIFFERENT_CARD_OWNER_PANEL)" class="input-container round-container" :ref="DIFFERENT_CARD_OWNER_PANEL" :open="openpanel === DIFFERENT_CARD_OWNER_PANEL" :active="activepanels.includes(DIFFERENT_CARD_OWNER_PANEL)" :title="$t('personal-data.panels.different-card-owner')">
                    <div class="panel-container">
                        <div class="input full header">
                            <label>{{ $t('general.personal-data') }}</label>
                        </div>

                        <TextInput idstub="ZmtpZW1haWw=" class="input" :error="$v.uicardowner.email.$error" v-model.trim="uicardowner.email" :required="$v.uicardowner.email.$params.required !== undefined" :label="$t('general.email')"/>
                        <span class="input" />

                        <DropdownInput idstub="ZmtpYW5yZWRl" class="input" :error="$v.uicardowner.salutation.$error" :defaultlabel="$t('general.please-select')" :label="$t('general.salutation')" :value="uicardowner.salutation.displayid" @input="event => uicardowner.salutation = event" :optionlist="salutations" :defaultoption="true" :required="$v.uicardowner.salutation.$params.required !== undefined"/>
                        <DropdownInput idstub="ZmtpdGl0ZWw=" class="input" :defaultlabel="$t('general.please-select')" :label="$t('general.title')" :value="uicardowner.title.displayid" @input="event => uicardowner.title = event" :optionlist="titles" :defaultoption="true" :required="$v.uicardowner.title.$params.required !== undefined"/>

                        <TextInput idstub="Zmtpdm9ybmFtZQ==" class="input" :error="$v.uicardowner.firstname.$error" v-model="uicardowner.firstname" :label="$t('general.firstname')" :required="$v.uicardowner.firstname.$params.required !== undefined"/>
                        <TextInput idstub="ZmtpbmFjaG5hbWU=" class="input" :error="$v.uicardowner.lastname.$error" v-model="uicardowner.lastname" :label="$t('general.lastname')" :required="$v.uicardowner.lastname.$params.required !== undefined"/>

                        <DateInput idstub="ZmtpZ2VidXJ0c3RhZw==" class="input" :error="$v.uicardowner.birthdate.$error" :errortext="cardownerageerror" v-model="uicardowner.birthdate" :label="$t('general.birthdate')" :required="$v.uicardowner.birthdate.$params.required !== undefined" :formatDate="_formatDate"/>
                        <TextInput idstub="ZmtpdGVsZWZvbg==" class="input" :error="$v.uicardowner.phone.$error" v-model="uicardowner.phone" :label="$t('general.phone-at-day')" :required="$v.uicardowner.phone.$params.required !== undefined"/>

                        <div class="input full header">
                            <label>{{ $t('general.address') }}</label>
                        </div>

                        <TextInput idstub="Zmtpc3RyYXNzZQ==" class="input" :error="$v.uicardowner.street.$error" v-model="uicardowner.street" :label="$t('general.street')" :required="$v.uicardowner.street.$params.required !== undefined"/>
                        <TextInput idstub="ZmtpaGF1c25y" class="input" :error="$v.uicardowner.housenr.$error" v-model="uicardowner.housenr" :label="$t('general.house-nr')" :required="$v.uicardowner.housenr.$params.required !== undefined"/>

                        <TextInput idstub="ZmtpaGF1c25yenVzYXR6" class="input" :error="$v.uicardowner.addressinfo.$error" v-model="uicardowner.addressinfo" :label="$t('general.address-addition')" :required="$v.uicardowner.addressinfo.$params.required !== undefined"/>
                        <span class="input" />

                        <TextInput idstub="ZmtpcGx6" class="input" :error="$v.uicardowner.zip.$error" v-model="uicardowner.zip" :label="$t('general.zip')" @input="getCity('uicardowner', $event)" :required="$v.uicardowner.zip.$params.required !== undefined"/>
                        <TextInput idstub="Zmtpb3J0" class="input" :error="$v.uicardowner.city.$error" v-model="uicardowner.city" :label="$t('general.city')" :required="$v.uicardowner.city.$params.required !== undefined"/>

                        <TextInput idstub="Zmtpb3J0c3RlaWw=" class="input" :error="$v.uicardowner.district.$error" v-model="uicardowner.district" :required="$v.uicardowner.district.$params.required !== undefined" :label="$t('general.district')"/>
                        <DropdownInput idstub="ZmtpbGFuZA==" class="input" :error="$v.uicardowner.country.$error" :value="uicardowner.country.displayid" @input="event => uicardowner.country = event" :defaultlabel="$t('general.please-select')" :label="$t('general.country')" :required="$v.uicardowner.country.$params.required !== undefined" :optionlist="countries" />

                        <div class="next-container input full">
                            <button id="fki-next" class="passive" @click="differentcardownerDataNext">
                                <label>{{ $t('general.next') }}</label>
                                <v-icon name="arrow-down"/>
                            </button>
                        </div>
                    </div>
                </Accordion>

                <Accordion @click="accordionClicked(BANKING_PANEL)" v-if="this.bankdataneeded" class="input-container round-container" :ref="BANKING_PANEL" :open="openpanel === BANKING_PANEL" :active="activepanels.includes(BANKING_PANEL)" :title="$t('personal-data.panels.bank-data')">
                    <div class="panel-container">
                        <div class="input full header">
                            <label>{{ $t('general.bank-account-data') }}</label>
                        </div>

                        <TextInput idstub="YmF2b3JuYW1l" class="input" v-if="!uicustomer.differentbankaccountowner" :display="true" :value="uicustomer.firstname + ' ' + uicustomer.lastname" :label="$t('general.bank-account-owner')"/>
                        <span v-if="!uicustomer.differentbankaccountowner" class="input placeholder" />

                        <TextInput idstub="aWJhbg==" class="input" :error="$v.uicustomer.iban.$error" v-model="uicustomer.iban" :label="$t('general.iban')" :required="$v.uicustomer.iban.$params.required !== undefined"/>
                        <TextInput idstub="Ymlj" class="input" :error="$v.uicustomer.bic.$error" v-model="uicustomer.bic" :label="$t('general.bic')" :required="$v.uicustomer.bic.$params.required !== undefined"/>

                        <div class="input full" v-if="sepahint">
                            <label class="sepa-hint" v-html="sepahint" />
                        </div>

                        <CheckboxInput idstub="c2VwYQ==" class="input" :error="$v.uicustomer.sepaconfirmed.$error" v-model="uicustomer.sepaconfirmed" :required="$v.uicustomer.sepaconfirmed.$params.required !== undefined" :text="$t('personal-data.allow-sepa-payments')"/>
                        <span class="input" />


                        <div class="input full header">
                            <label>{{ $t('personal-data.different-bank-account-owner') }}</label>
                        </div>

                        <CheckboxInput idstub="YWJ3ZWljaGVuZGVya3Rv" class="input" :text="$t('personal-data.different-bank-account-owner')" v-model="uicustomer.differentbankaccountowner" :required="$v.uicustomer.differentbankaccountowner.$params.required !== undefined"/>
                        <span class="input" />

                        <div class="next-container input full">
                            <button id="banking-next" :class="BANKING_PANEL === LAST_PANEL ? 'active' : 'passive'" @click="bankingDataNext">
                                <label>{{ BANKING_PANEL === LAST_PANEL ? $t('general.done') : $t('general.next') }}</label>
                                <v-icon :name="BANKING_PANEL === LAST_PANEL ? 'check' : 'arrow-down'"/>
                            </button>
                        </div>
                    </div>
                </Accordion>

                <Accordion v-if="uicustomer.differentbankaccountowner" @click="accordionClicked(BANKING_OWNER_PANEL)" class="input-container round-container" :ref="BANKING_OWNER_PANEL" :open="openpanel === BANKING_OWNER_PANEL" :active="activepanels.includes(BANKING_OWNER_PANEL)" :title="$t('personal-data.panels.different-bank-account-owner')">
                    <div class="panel-container">
                        <div class="input full header">
                            <label>{{ $t('general.personal-data') }}</label>
                        </div>

                        <DropdownInput idstub="a3RvYW5yZWRl" class="input" :error="$v.uibankaccountowner.salutation.$error" :value="uibankaccountowner.salutation.displayid" @input="event => uibankaccountowner.salutation = event" :optionlist="salutations" :defaultlabel="$t('general.please-select')" :label="$t('general.salutation')" :required="$v.uibankaccountowner.salutation.$params.required !== undefined" :defaultoption="true"/>
                        <DropdownInput idstub="a3RvdGl0ZWw=" class="input" :error="$v.uibankaccountowner.title.$error" :value="uibankaccountowner.title.displayid" @input="event => uibankaccountowner.title = event" :optionlist="titles" :defaultlabel="$t('general.please-select')" :label="$t('general.title')" :required="$v.uibankaccountowner.title.$params.required !== undefined" :defaultoption="true"/>

                        <TextInput idstub="a3Rvdm9ybmFtZQ==" class="input" :error="$v.uibankaccountowner.firstname.$error" v-model="uibankaccountowner.firstname" :label="$t('general.firstname')" :required="$v.uibankaccountowner.firstname.$params.required !== undefined"/>
                        <TextInput idstub="a3RvbmFjaG5hbWU=" class="input" :error="$v.uibankaccountowner.lastname.$error" v-model="uibankaccountowner.lastname" :label="$t('general.lastname')" :required="$v.uibankaccountowner.lastname.$params.required !== undefined"/>

                        <DateInput idstub="a3RvZ2VidXJ0c3RhZw==" class="input" :error="$v.uibankaccountowner.birthdate.$error" :errortext="accountownerageerror" v-model="uibankaccountowner.birthdate" :label="$t('general.birthdate')" :required="$v.uibankaccountowner.birthdate.$params.required !== undefined" :formatDate="_formatDate"/>
                        <TextInput idstub="a3RvdGVsZWZvbg==" class="input" :error="$v.uibankaccountowner.phone.$error" v-model="uibankaccountowner.phone" :label="$t('general.phone-at-day')" :required="$v.uibankaccountowner.phone.$params.required !== undefined"/>

                        <div class="input full header">
                            <label>{{ $t('general.address') }}</label>
                        </div>

                        <TextInput idstub="a3Rvc3RyYXNzZQ==" class="input" :error="$v.uibankaccountowner.street.$error" v-model="uibankaccountowner.street" :label="$t('general.street')" :required="$v.uibankaccountowner.street.$params.required !== undefined"/>
                        <TextInput idstub="a3RvaGF1c25y" class="input" :error="$v.uibankaccountowner.housenr.$error" v-model="uibankaccountowner.housenr" :label="$t('general.house-nr')" :required="$v.uibankaccountowner.housenr.$params.required !== undefined"/>

                        <TextInput idstub="a3RvaGF1c25yenVzYXR6" class="input" :error="$v.uibankaccountowner.addressinfo.$error" v-model="uibankaccountowner.addressinfo" :required="$v.uibankaccountowner.addressinfo.$params.required !== undefined" :label="$t('general.address-addition')" />
                        <span class="input" />

                        <TextInput idstub="a3RvcGx6" class="input" :error="$v.uibankaccountowner.zip.$error" v-model="uibankaccountowner.zip" :label="$t('general.zip')" @input="getCity('uibankaccountowner', $event)" :required="$v.uibankaccountowner.zip.$params.required !== undefined"/>
                        <TextInput idstub="a3Rvb3J0" class="input" :error="$v.uibankaccountowner.city.$error" v-model="uibankaccountowner.city" :label="$t('general.city')" :required="$v.uibankaccountowner.city.$params.required !== undefined"/>

                        <TextInput idstub="a3Rvb3J0c3RlaWw=" class="input" :error="$v.uibankaccountowner.district.$error" v-model="uibankaccountowner.district" :required="$v.uibankaccountowner.district.$params.required !== undefined" :label="$t('general.district')"/>
                        <DropdownInput idstub="a3RvbGFuZA==" class="input" :defaultlabel="$t('general.please-select')" :label="$t('general.country')" :error="$v.uibankaccountowner.country.$error" :value="uibankaccountowner.country.displayid" @input="event => uibankaccountowner.country = event" :required="$v.uibankaccountowner.country.$params.required !== undefined" :optionlist="countries" />

                        <div class="next-container input full">
                            <button id="kto-next" :class="BANKING_OWNER_PANEL === LAST_PANEL ? 'active' : 'passive'" @click="differentbankaccountownerDataNext">
                                <label>{{ BANKING_OWNER_PANEL === LAST_PANEL ? $t('general.done') : $t('general.next') }}</label>
                                <v-icon :name="BANKING_OWNER_PANEL === LAST_PANEL ? 'check' : 'arrow-down'"/>
                            </button>
                        </div>
                    </div>
                </Accordion>

                <Accordion v-if="showPicturePanel()" @click="accordionClicked(PICTURE_PANEL)" class="input-container round-container" :ref="PICTURE_PANEL" :open="openpanel === PICTURE_PANEL" :active="activepanels.includes(PICTURE_PANEL)" :title="$t('personal-data.panels.picture')">
                    <div class="panel-container">
                        <div class="input full header">
                            <label>{{ $t('personal-data.card-owner-picture') }}{{ $v.uicroppedpicture.$params.required !== undefined ? '*' : ' (' + $t('general.optional') + ')'}}</label>
                        </div>

                        <span class="input full additional-info">{{ $t('personal-data.picture-max-size') }}</span>

                        <FileUpload inputid="aW1hZ2V1cGxvYWQ=" class="picture-upload input" :uploadText="$t('personal-data.picture-upload-hint')" :limit="1" v-model="pictureUpload" acceptedFileTypes="image/jpeg,image/bmp,image/png" :sizeLimitMb="5"/>

                        <div class="input image-container" :class="{'error':this.$v.uicroppedpicture.$error}" @click="$refs.imgbutton.click()">
                            <img id="fki-img" :src="uicroppedpicture" class="customer-image">
                            <v-icon v-if="!uicroppedpicture" class="image-placeholder" name="portrait" />
                        </div>

                        <div class="next-container input full">
                            <button id="picture-next" :class="[ this.showCustomFields() ? 'passive' : 'active']" @click="pictureDataNext">
                                <label>{{ this.showCustomFields() ? $t('general.next') : $t('general.done') }}</label>
                                <v-icon :name="this.showCustomFields() ? 'arrow-down' : 'check'"/>
                            </button>
                        </div>
                    </div>
                </Accordion>

                <Accordion v-if="showCustomFields()" @click="accordionClicked(REFERENCE_PANEL)" class="input-container round-container" :ref="REFERENCE_PANEL" :open="openpanel === REFERENCE_PANEL" :active="activepanels.includes(REFERENCE_PANEL)" :title="$t('general.references')">
                    <div class="panel-container">
                        <div class="input full header" v-if="showanyfield">
                            <label>{{ $t('general.references') }}</label>
                        </div>

                        <div class="field-input" v-if="getFieldConfig(1) && (getFieldConfig(1).Anzeigen === 'true' || getFieldConfig(1).Anzeigen === true)">
                            <TextInput idstub="ZmVsZDE=" class="input" :error="$v.uicustomer.field1.$error" :disabled="uicustomer.disabledfield1" v-model="uicustomer.field1" :label="getFieldConfig(1).externeBezeichnung" :required="$v.uicustomer.field1.$params.required !== undefined"/>
                            <label idstub="ZmVsZDF6" class="field-text" v-if="getFieldConfig(1).Zusatztext">{{ getFieldConfig(1).Zusatztext }}</label>
                        </div>
                        <div class="field-input" v-if="getFieldConfig(2) && (getFieldConfig(2).Anzeigen === 'true' || getFieldConfig(2).Anzeigen === true)">
                            <TextInput idstub="ZmVsZDI=" class="input" :error="$v.uicustomer.field2.$error" :disabled="uicustomer.disabledfield2" v-model="uicustomer.field2" :label="getFieldConfig(2).externeBezeichnung" :required="$v.uicustomer.field2.$params.required !== undefined"/>
                            <label idstub="ZmVsZDJ6" class="field-text" v-if="getFieldConfig(2).Zusatztext">{{ getFieldConfig(2).Zusatztext }}</label>
                        </div>
                        <div class="field-input" v-if="getFieldConfig(3) && (getFieldConfig(3).Anzeigen === 'true' || getFieldConfig(3).Anzeigen === true)">
                            <TextInput idstub="ZmVsZDM=" class="input" :error="$v.uicustomer.field3.$error" :disabled="uicustomer.disabledfield3" v-model="uicustomer.field3" :label="getFieldConfig(3).externeBezeichnung" :required="$v.uicustomer.field3.$params.required !== undefined"/>
                            <label idstub="ZmVsZDN6" class="field-text" v-if="getFieldConfig(3).Zusatztext">{{ getFieldConfig(3).Zusatztext }}</label>
                        </div>

                        <div class="next-container input full">
                            <button id="ref-next" class="active" @click="referenceDataNext">
                                <label>{{ $t('general.done') }}</label>
                                <v-icon name="check"/>
                            </button>
                        </div>
                    </div>
                </Accordion>
            </div>
            <div class="information-wrapper">
                <TicketInformationContainer :product="this.selectedproduct" :relation="this.relation"/>

                <button id="general-next" class="active complete-container" @click="goToCompleteOrder()" :disabled="!done">
                    <label>{{ $t('general.next') }}</label>
                    <label>{{ $t('personal-data.proceed-to-complete') }}</label>
                </button>
            </div>
        </div>

        <UploadCropper v-model="showcropper" :croponly="true" :src="picToCrop" @picture="handlePicture"/>
    </div>
</template>

<script>
import Accordion from '../components/systems/elements/Accordion.vue'
import TextInput from '../components/systems/elements/TextInput.vue'
import DateInput from '../components/systems/DateInput.vue'
import FileUpload from '../components/systems/FileUpload.vue'
import UploadCropper from '../components/systems/ImageUploadCropper.vue'

import 'vue-awesome/icons/arrow-right'
import 'vue-awesome/icons/portrait'
import 'vue-awesome/icons/check'

import TicketInformationContainer from '../components/systems/TicketInformationContainer.vue'
import CheckboxInput from '../components/systems/elements/CheckboxInput.vue'
import DropdownInput from '../components/systems/elements/DropdownInput.vue'

import Vue from 'vue'
import axios from 'axios'

import {
    required,
    email,
    sameAs,
    minLength,
    maxLength
} from 'vuelidate/lib/validators'

export default {
    name: 'PersonalDataPage',
    data() {
        return {
            showcropper: false,
            openpanel: undefined,
            activepanels: [],
            bichint: this.$t('personal-data.bichint'),
            accounthint: this.$t('personal-data.accounthint'),
            uicroppedpicture: undefined,
            mailalreadyinuse: false,
            ibanchecked: false,
            pictureUpload: [],
            picToCrop: '',
            uicustomer: {
                email: '',
                confirmemail: '',
                createaccount: false,
                salutation: { displayid: -1, displayname: ''},
                title: { displayid: -1, displayname: ''},
                firstname: '',
                lastname: '',
                birthdate: '',
                phone: '',
                street: '',
                housenr: '',
                district: '',
                zip: '',
                city: '',
                country: { displayid: -1, displayname: ''},
                addressinfo: '',
                differentcardowner: this.$root.$data.product.original.AbweichenderKarteninhaber,
                iban: '',
                bic: '',
                differentbankaccountowner: false,
                sepaconfirmed: false,
                field1: '',
                field2: '',
                field3: '',
                voucher: ''
            },
            uicardowner: {
                email: '',
                salutation: { displayid: -1, displayname: ''},
                title: { displayid: -1, displayname: ''},
                firstname: '',
                lastname: '',
                birthdate: '',
                phone: '',
                street: '',
                housenr: '',
                district: '',
                zip: '',
                city: '',
                addressinfo: '',
                country: { displayid: -1, displayname: ''}
            },
            uibankaccountowner: {
                salutation: { displayid: -1, displayname: ''},
                title: { displayid: -1, displayname: ''},
                firstname: '',
                lastname: '',
                birthdate: '',
                phone: '',
                street: '',
                housenr: '',
                district: '',
                zip: '',
                city: '',
                addressinfo: '',
                country: { displayid: -1, displayname: ''}
            }
        }
    },
    validations() {
        const isOfLegalAge = (minage, monthoffset = 0) => (value) => {
            if (!value) {
                return false
            }

            const today = new Date()

            // deconstruct a german date
            const dateparts = value.match(/(\d+)/g)
            const birthDate = new Date(dateparts[2], dateparts[1]-1, dateparts[0])

            // of an offset for ordering is set, shift the date back 6 months
            // to accomodate for that
            if (monthoffset) {
                birthDate.setMonth(birthDate.getMonth() - monthoffset)
            }

            let age = today.getFullYear() - birthDate.getFullYear()
            // 1. if the user was born e.g. in september '14 (09.2014) but we are in august '22 (08.2022) -> 8 - 9 = -1
            //    he is still one month too young, even if the year difference is exactly 18 years
            // 2. if an offset is configured, add it to the difference, to simulate a later birthday
            //    2 month offset -> 9 - 8 + 2 = 1, so the user can order the ticket 2 months ahead
            let monthdifference = today.getMonth() - birthDate.getMonth()

            if (monthdifference < 0 || (monthdifference === 0 && today.getDate() < birthDate.getDate())) {
                age--
            }

            return age >= minage
        }

        let validation = {
            uicustomer: {
                email: {
                    required,
                    email
                },
                createaccount: {},
                salutation: {
                    required,
                    notdefault: value => value.displayid !== -1
                },
                title: {},
                firstname: {
                    required
                },
                lastname: {
                    required
                },
                birthdate: {
                    required
                },
                phone: {},
                street: {
                    required
                },
                housenr: {
                    required
                },
                district: {},
                zip: {
                    required
                },
                city: {
                    required
                },
                addressinfo: {},
                country: {},
                differentcardowner: {},
                differentbankaccountowner: {},
                field1: {},
                field2: {},
                field3: {},
            },
            uicroppedpicture: {}
        }

        if(!this.$root.$data.isexistingcustomer) {
            validation.uicustomer.confirmemail = {
                required,
                email,
                sameAsEmail: sameAs('email')
            }
        }

        if (this.uicustomer.createaccount) {
            validation.uicustomer.createaccount = {
                notalreadyinuse: () => !this.mailalreadyinuse
            }
        }

        if(this.zipLengthCustomer) {
            validation.uicustomer.zip = {
                required,
                minLength: minLength(this.zipLengthCustomer.displayvalue),
                maxLength: maxLength(this.zipLengthCustomer.displayvalue)
            }
        }

        if (this.bankdataneeded) {
            validation.uicustomer.iban = {
                // Norway (NO) has shortest IBAN with 15 chars
                format: value => !!value.replaceAll(' ', '').match(/^[a-zA-Z]{2}[0-9]{13,}$/),
                required
            }

            validation.uicustomer.bic = {}

            validation.uicustomer.sepaconfirmed = {
                checked: value => value === true
            }
        }

        // PictureActive: 0 - not available, 1 - required, 2 - available
        if (!this.$root.$data.isexistingcustomer && this.selectedproduct.original.PictureActive === 1) {
            validation.uicroppedpicture = {
                required
            }
        }

        if (this.uicustomer.differentcardowner) {
            validation.uicardowner = {
                email: {
                    required,
                    email
                },
                salutation: {
                    required,
                    notdefault: value => value.displayid !== -1
                },
                title: {},
                firstname: {
                    required
                },
                lastname: {
                    required
                },
                birthdate: {
                    required
                },
                phone: {},
                street: {
                    required
                },
                housenr: {
                    required
                },
                district: {},
                zip: {
                    required
                },
                city: {
                    required
                },
                addressinfo: {},
                country: {}
            }

            if(this.zipLengthCardOwner) {
                validation.uicardowner.zip = {
                    required,
                    minLength: minLength(this.zipLengthCardOwner.displayvalue),
                    maxLength: maxLength(this.zipLengthCardOwner.displayvalue)
                }
            }
        }

        if (this.uicustomer.differentbankaccountowner) {
            validation.uibankaccountowner = {
                salutation: {
                    required,
                    notdefault: value => value.displayid !== -1
                },
                title: {},
                firstname: {
                    required
                },
                lastname: {
                    required
                },
                birthdate: {
                    required
                },
                phone: {},
                street: {
                    required
                },
                housenr: {
                    required
                },
                district: {},
                zip: {
                    required
                },
                city: {
                    required
                },
                addressinfo: {},
                country: {}
            }

            if(this.zipLengthBankAccountOwner) {
                validation.uibankaccountowner.zip = {
                    required,
                    minLength: minLength(this.zipLengthBankAccountOwner.displayvalue),
                    maxLength: maxLength(this.zipLengthBankAccountOwner.displayvalue)
                }
            }
        }

        // BIC validation, only if IBAN is not german
        if (this.uicustomer
                && this.uicustomer.iban.length > 0
                && !this.uicustomer.iban.toLowerCase().startsWith('de')) {
            validation.uicustomer.bic = {
                required
            }
        }

        //age validations:
        //3 different legal ages:
        //account,cardowner, bankaccount

        if(this.accountlegalage) {
            validation.uicustomer.birthdate = {
                ...validation.uicustomer.birthdate,
                accountownerlegal: isOfLegalAge(this.accountlegalage)
            }
        }

        if(this.uicustomer.differentcardowner && this.productlegalage) {
            validation.uicardowner.birthdate = {
                ...validation.uicardowner.birthdate,
                productlegal: isOfLegalAge(this.productlegalage, this.productlegalageoffset)
            }
        }

        //if there is no different cardowner, the customer must be of legal age for cardowner
        if(this.activepanels.includes(this.ADDRESS_PANEL) && !this.uicustomer.differentcardowner && this.productlegalage > this.accountlegalage) {
            validation.uicustomer.birthdate = {
                ...validation.uicustomer.birthdate,
                productlegal: isOfLegalAge(this.productlegalage, this.productlegalageoffset)
            }
        }

        if(this.differentbankaccountowner) {
            if(this.bankaccountlegalage) {
                validation.uibankaccountowner.birthdate = {
                    ...validation.uibankaccountowner.birthdate,
                    accountownerlegal: isOfLegalAge(this.bankaccountlegalage)
                }
            } else {
                validation.uibankaccountowner.birthdate = {
                    ...validation.uibankaccountowner.birthdate,
                    accountownerlegal: isOfLegalAge(this.accountlegalage)
                }
            }
        }

        if(this.activepanels.includes(this.BANKING_PANEL) && !this.uicustomer.differentbankaccountowner && this.bankaccountlegalage > this.accountlegalage) {
            //prevent reducing the cusomer legal age, if the product legal age is higher than bank account legal age and thers no different cardowner
            if(this.bankaccountlegalage > this.productlegalage) {
                validation.uicustomer.birthdate = {
                    ...validation.uicustomer.birthdate,
                    accountownerlegal: isOfLegalAge(this.bankaccountlegalage)
                }
                //if there is a different cardowner, the customer age have to be at least the bank account legal age
            } else if(this.uicustomer.differentcardowner) {
                validation.uicustomer.birthdate = {
                    ...validation.uicustomer.birthdate,
                    accountownerlegal: isOfLegalAge(this.bankaccountlegalage)
                }
            }
        }

        if (this.getFieldConfig(1)
            && (this.getFieldConfig(1).Anzeigen === 'true' || this.getFieldConfig(1).Anzeigen === true)
            && (this.getFieldConfig(1).Pflichtfeld === 'true' || this.getFieldConfig(1).Pflichtfeld === true)) {
                validation.uicustomer.field1 = {
                    required
                }
        }

        if (this.getFieldConfig(2)
            && (this.getFieldConfig(2).Anzeigen === 'true' || this.getFieldConfig(2).Anzeigen === true)
            && (this.getFieldConfig(2).Pflichtfeld === 'true' || this.getFieldConfig(2).Pflichtfeld === true)) {
                validation.uicustomer.field2 = {
                    required
                }
        }

        if (this.getFieldConfig(3)
            && (this.getFieldConfig(3).Anzeigen === 'true' || this.getFieldConfig(3).Anzeigen === true)
            && (this.getFieldConfig(3).Pflichtfeld === 'true' || this.getFieldConfig(3).Pflichtfeld === true)) {
                validation.uicustomer.field3 = {
                    required
                }
        }

        return validation
    },
    created() {
        // "const" declarations
        this.PERSONAL_PANEL             = 0
        this.ADDRESS_PANEL              = 1
        this.DIFFERENT_CARD_OWNER_PANEL = 2
        this.BANKING_PANEL              = 3
        this.BANKING_OWNER_PANEL        = 4
        this.PICTURE_PANEL              = 5
        this.REFERENCE_PANEL            = 6

        if (this.showCustomFields()) {
            this.LAST_PANEL = this.REFERENCE_PANEL
        } else if (this.showPicturePanel()) {
            this.LAST_PANEL = this.PICTURE_PANEL
        } else if (this.bankdataneeded) {
            this.LAST_PANEL = this.BANKING_PANEL
        } else {
            this.LAST_PANEL = this.ADDRESS_PANEL
        }

        this.openpanel = this.PERSONAL_PANEL
        this.activepanels.push(this.openpanel)

        // prepare customer
        if (this.customer) {
            this.uicustomer = this.customer
        }

        if (this.cardowner) {
            this.uicardowner = this.cardowner
        }

        if (this.bankaccountowner) {
            this.uibankaccountowner = this.bankaccountowner
        }

        if (this.croppedpicture) {
            this.uicroppedpicture = this.croppedpicture
        }
    },
    mounted() {
        if (this.customer && (this.customer.lbvid || this.customer.bcode)) {
            this.initFieldsFromQueryParams(this.customer.lbvid, this.customer.bcode)
        }

        // set germany as default
        const germany = this.countries.find(country => country.original.toLowerCase() === 'de')

        if (germany !== undefined) {
            const germanyIdx = this.countries.indexOf(germany)
            this.uicustomer.country          = this.countries[germanyIdx]
            this.uicardowner.country         = this.countries[germanyIdx]
            this.uibankaccountowner.country  = this.countries[germanyIdx]
        }
    },
    watch: {
        pictureUpload: {
            handler(value) {
                if (value && Object.keys(value).length > 0) {
                    const reader = new FileReader()
                    reader.addEventListener('load', (event) => {
                        this.picToCrop     = event.target.result
                        this.pictureUpload = []
                        this.startCropper()
                    })

                    reader.readAsDataURL(value[0])
                }
            }
        },
        uicustomer: {
            handler(value) {
                this.customer = value
            },
            deep: true
        },
        uicardowner: {
            handler(value) {
                this.cardowner = value
            },
            deep: true
        },
        uibankaccountowner: {
            handler(value) {
                this.bankaccountowner = value
            },
            deep: true
        },
        uicroppedpicture(value) {
            this.croppedpicture = value
        },
        // BANKING_OWNER_PANEL can be the last panel if differentbankaccountowner is selected
        differentbankaccountowner: {
            handler(value) {
                if(value && this.LAST_PANEL === this.BANKING_PANEL) {
                    this.LAST_PANEL = this.BANKING_OWNER_PANEL
                }
                if(!value && this.LAST_PANEL === this.BANKING_OWNER_PANEL) {
                    this.LAST_PANEL = this.BANKING_PANEL
                }
            }
        }
    },
    computed: {
        customerHasData() {
            return this.$root.$data.customerHasData || {}
        },
        salutations() {
            return this.$root.$data.basedata.salutations
        },
        titles() {
            return this.$root.$data.basedata.titles
        },
        countries() {
            return this.$root.$data.basedata.countries
        },
        zipLengthCustomer() {
            const countryZipLength = this.$root.$data.basedata.zipLength
            const zipLength = countryZipLength.find(({displayid}) => displayid === this.uicustomer.country.original)

            return zipLength
        },
        zipLengthCardOwner() {
            const countryZipLength = this.$root.$data.basedata.zipLength
            const zipLength = countryZipLength.find(({displayid}) => displayid === this.uicardowner.country.original)

            return zipLength
        },
        zipLengthBankAccountOwner() {
            const countryZipLength = this.$root.$data.basedata.zipLength
            const zipLength = countryZipLength.find(({displayid}) => displayid === this.uibankaccountowner.country.original)

            return zipLength
        },
        mailalreadyinusetext() {
            return this.mailalreadyinuse ? this.$t('personal-data.errors.mail-already-in-use') : ''
        },
        allowaccountcreation() {
            return this.$root.$data.systemconfiguration.anlageassistent.allowaccountcreation && !this.$root.$data.isexistingcustomer
        },
        productwithpicture() {
            return this.selectedproduct.original.PictureActive !== 0
        },
        customerageerror() {
            let productcheck
            if(this.uicustomer.differentcardowner) {
                productcheck = this.$v.uicardowner.birthdate.productlegal !== undefined
            } else {
                productcheck = this.$v.uicustomer.birthdate.productlegal !== undefined
            }

            const accountcheck = this.$v.uicustomer.birthdate.accountownerlegal !== undefined

            let agelimit
            let offset
            let useaccountnotproduct = true

            if (productcheck && accountcheck) {
                useaccountnotproduct = this.accountlegalage > this.productlegalage
            } else {
                useaccountnotproduct = (!productcheck && accountcheck)
            }

            if (useaccountnotproduct) {
                agelimit = this.accountlegalage
            } else {
                agelimit = this.productlegalage
                offset = this.productlegalageoffset
            }

            let result = this.$t('personal-data.errors.product-legal-age', { age: agelimit })
            if (offset && offset > 0) {
                result += ' ' + this.$t('personal-data.errors.product-legal-age-offset', { offset: offset })
            }

            if(this.activepanels.includes(this.BANKING_PANEL) && !this.uicustomer.differentbankaccountowner && this.bankaccountlegalage > agelimit) {
                result = this.$t('personal-data.errors.account-owner-legal-age', { age: this.bankaccountlegalage })
            }

            return result
        },
        cardownerageerror() {

            const agelimit = this.productlegalage
            const offset = this.productlegalageoffset

            let result = this.$t('personal-data.errors.product-legal-age', { age: agelimit })
            if (offset && offset > 0) {
                result += ' ' + this.$t('personal-data.errors.product-legal-age-offset', { offset: offset })
            }

            return result
        },
        accountownerageerror() {
            return this.$t('personal-data.errors.account-owner-legal-age', { age: this.bankaccountlegalage })
        },
        accountlegalage() {
            return this.$root.$data.systemconfiguration.anlageassistent.accountownerlegalage
        },
        bankaccountlegalage() {
            return this.$root.$data.systemconfiguration.anlageassistent.bankaccountownerlegalage
        },
        productlegalage() {
            return this.selectedproduct.original.Mindestalter
        },
        productlegalageoffset() {
            return this.$root.$data.systemconfiguration.anlageassistent.productlegalageoffsetinmonths
        },
        done() {
            let done = this.activepanels.includes(this.LAST_PANEL) && !this.$v.$anyError && !this.$v.$invalid

            // the banking panel CAN be the last panel
            // the iban check is done upon leaving the panel
            // so we add the iban check to the done condition
            if (this.bankdataneeded) {
                done = done && this.ibanchecked
            }

            return done
        },
        selectedproduct() {
            return this.$root.$data.product
        },
        relation() {
            return this.$root.$data.relation
        },
        customer: {
            get() {
                return this.$root.$data.customer
            },
            set(value) {
                this.$root.$data.customer = value
            }
        },
        cardowner: {
            get() {
                return this.$root.$data.cardowner
            },
            set(value) {
                this.$root.$data.cardowner = value
            }
        },
        bankaccountowner: {
            get() {
                return this.$root.$data.bankaccountowner
            },
            set(value) {
                this.$root.$data.bankaccountowner = value
            }
        },
        croppedpicture: {
            get() {
                return this.$root.$data.croppedpicture
            },
            set(value) {
                this.$root.$data.croppedpicture = value
            }
        },
        showanyfield() {
            const showfield1 = this.getFieldConfig(1) && (this.getFieldConfig(1).Anzeigen === 'true' || this.getFieldConfig(1).Anzeigen === true)
            const showfield2 = this.getFieldConfig(2) && (this.getFieldConfig(2).Anzeigen === 'true' || this.getFieldConfig(2).Anzeigen === true)
            const showfield3 = this.getFieldConfig(3) && (this.getFieldConfig(3).Anzeigen === 'true' || this.getFieldConfig(3).Anzeigen === true)
            return showfield1 || showfield2 || showfield3
        },
        bankdataneeded() {
            const bankverbindungsmodus = this.selectedproduct.original.BankverbindungsModus

            // TO values:
            // - Keine
            // - Abocenter
            // - Paymentgateway

            if (bankverbindungsmodus === 'Keine') {
                return false
            } else {
                return true
            }
        },
        sepahint() {
            // 9 === MANDAT
            return this.getText(9)
        },
        differentbankaccountowner() {
            return this.uicustomer.differentbankaccountowner
        }
    },
    methods: {
        showPicturePanel() {
            return !this.$root.$data.isexistingcustomer && this.productwithpicture
        },
        getText(variant) {
            var result = undefined
            if (this.selectedproduct.original.Infotexte
                    && this.selectedproduct.original.Infotexte.TOWSInfotext) {
                const text = this.selectedproduct.original.Infotexte.TOWSInfotext.find(entry => entry.Variante === variant)
                if (text) result = text.Text
            }
            return result
        },
        initFieldsFromQueryParams(lbvid, bcode) {
            this.$root.$data.systemconfiguration.fields?.forEach(field => {
                if (field.FeldName === 'Personalnummer') {
                    switch(field.FeldID) {
                        case 1:
                            this.uicustomer.field1 = bcode
                            this.uicustomer.disabledfield1 = true
                            break;
                        case 2:
                            this.uicustomer.field2 = bcode
                            this.uicustomer.disabledfield2 = true
                            break;
                        case 3:
                            this.uicustomer.field3 = bcode
                            this.uicustomer.disabledfield3 = true
                            break;
                        default:
                            break;
                    }
                } else if (field.FeldName === 'Abteilungsnummer') {
                    switch(field.FeldID) {
                        case 1:
                            this.uicustomer.field1 = lbvid
                            this.uicustomer.disabledfield1 = true
                            break;
                        case 2:
                            this.uicustomer.field2 = lbvid
                            this.uicustomer.disabledfield2 = true
                            break;
                        case 3:
                            this.uicustomer.field3 = lbvid
                            this.uicustomer.disabledfield3 = true
                            break;
                        default:
                            break;
                    }
                }
            })
        },
        showCustomFields() {
            const showFields = this.$root.$data.systemconfiguration.fields && this.$root.$data.systemconfiguration.fields.find(entry => entry.Anzeigen === true || entry.Anzeigen === 'true')
            return showFields
        },
        getFieldConfig(fieldid) {
            return this.$root.$data.systemconfiguration.fields?.find(field => field.FeldID === fieldid)
        },
        resetCreateaccount(event) {
            if(event === false) {
                this.mailalreadyinuse = false
            }
        },
        getCity(changeValue, zip) {
            if((changeValue === 'uicustomer' && zip.toString().length === 5)
                || (changeValue === 'uicardowner' && zip.toString().length === 5)
                || (changeValue === 'uibankaccountowner' && zip.toString().length === 5)) {
                let url = Vue.middleware()
                url += '/city/'
                url += zip

                /* this.$root.$data.loading = true */

                axios.get(url)
                .then(response => {

                    const city = response.data.city

                    if(response.data.city) {
                        if (changeValue === 'uicustomer' && this.uicustomer.country.original === 'DE') {
                            this.uicustomer.city = city
                        } else if (changeValue === 'uicardowner' && this.uicardowner.country.original === 'DE') {
                            this.uicardowner.city = city
                        } else if (changeValue === 'uibankaccountowner' && this.uibankaccountowner.country.original === 'DE') {
                            this.uibankaccountowner.city = city
                        }
                    }
                    
                    this.$root.$data.loading = false
                })
                .catch(err => {
                    this.$root.$data.loading = false
                    //TODO
                    console.log(err)
                })
            }
        },
        personalDataNext: async function() {
            if (this.uicustomer.createaccount) {
                this.$root.$data.loading = true

                this.mailalreadyinuse = false

                let checkmailurl = Vue.middleware()
                checkmailurl += '/userprofile/check-email'

                const body = {
                    email: this.uicustomer.email
                }

                try {
                    const response = await axios.put(checkmailurl, body)

                    if (response.data.isexisting) {
                        this.mailalreadyinuse = response.data.isexisting
                        this.$v.uicustomer.createaccount.$touch()
                    }

                    this.$root.$data.loading = false
                } catch(err) {
                    this.$root.$data.loading = false
                    Vue.$toast.error(err)
                    return
                }
            }

            this.$v.uicustomer.salutation.$touch()
            this.$v.uicustomer.title.$touch()
            this.$v.uicustomer.email.$touch()

            if (!this.$root.$data.isexistingcustomer) {
                this.$v.uicustomer.confirmemail.$touch()
            }

            this.$v.uicustomer.firstname.$touch()
            this.$v.uicustomer.lastname.$touch()
            this.$v.uicustomer.birthdate.$touch()

            if (this.$v.$anyError || this.mailalreadyinuse) {
                return
            }

            this.nextPanel(this.PERSONAL_PANEL)
        },
        adressDataNext() {
            this.$v.uicustomer.street.$touch()
            this.$v.uicustomer.housenr.$touch()
            this.$v.uicustomer.zip.$touch()
            this.$v.uicustomer.city.$touch()

            if (!this.$v.$anyError) {
                this.nextPanel(this.ADDRESS_PANEL)

                //set adress data of different cardowner
                if (this.uicustomer.differentcardowner) {
                    if (!this.uicardowner.street && !this.uicardowner.housenr && !this.uicardowner.addressinfo && !this.uicardowner.zip && !this.uicardowner.city && !this.uicardowner.district) {
                        this.uicardowner.street = this.uicustomer.street
                        this.uicardowner.housenr = this.uicustomer.housenr
                        this.uicardowner.addressinfo = this.uicustomer.addressinfo
                        this.uicardowner.zip = this.uicustomer.zip
                        this.uicardowner.city = this.uicustomer.city
                        this.uicardowner.district = this.uicustomer.district
                        this.uicardowner.country = this.uicustomer.country
                    }
                }
            }
        },
        differentcardownerDataNext() {
            this.$v.uicardowner.email.$touch()
            this.$v.uicardowner.firstname.$touch()
            this.$v.uicardowner.lastname.$touch()
            this.$v.uicardowner.birthdate.$touch()
            this.$v.uicardowner.salutation.$touch()

            this.$v.uicardowner.street.$touch()
            this.$v.uicardowner.housenr.$touch()
            this.$v.uicardowner.zip.$touch()
            this.$v.uicardowner.city.$touch()

            if (!this.$v.$anyError) {
                this.nextPanel(this.DIFFERENT_CARD_OWNER_PANEL)
            }
        },
        bankingDataNext() {
            if (this.$v.uicustomer.bic.$params.required !== undefined) {
                this.$v.uicustomer.bic.$touch()
            }

            this.$v.uicustomer.iban.$touch()
            this.$v.uicustomer.sepaconfirmed.$touch()

            if (this.$v.$anyError) {
                return
            }

            // let to check the iban/bic combination
            this.$root.$data.loading = true
            this.ibanchecked = false

            let checkibanurl = Vue.middleware()
            checkibanurl += '/checkiban/' + this.uicustomer.iban

            if (this.uicustomer.bic) {
                checkibanurl += '?bic=' + this.uicustomer.bic
            }

            axios.get(checkibanurl)
            .then(response => {
                if (response.data.status.ErrorCode !== 0) {
                    this.ibanchecked = false
                    Vue.$toast.error(response.data.status.ErrorMsg)
                } else {
                    this.ibanchecked = true
                    this.nextPanel(this.BANKING_PANEL)
                }

                this.$root.$data.loading = false
            })
            .catch(err => {
                console.log(err)
                Vue.$toast.error(this.$t('Internal Error'))
                this.$root.$data.loading = false
            })
        },
        differentbankaccountownerDataNext() {
            this.$v.uibankaccountowner.firstname.$touch()
            this.$v.uibankaccountowner.lastname.$touch()
            this.$v.uibankaccountowner.birthdate.$touch()
            this.$v.uibankaccountowner.salutation.$touch()

            this.$v.uibankaccountowner.street.$touch()
            this.$v.uibankaccountowner.housenr.$touch()
            this.$v.uibankaccountowner.zip.$touch()
            this.$v.uibankaccountowner.city.$touch()

            if (!this.$v.$anyError) {
                this.nextPanel(this.BANKING_OWNER_PANEL)
            }
        },
        pictureDataNext() {
            this.$v.uicroppedpicture.$touch()

            if (!this.$v.$anyError) {
                this.nextPanel(this.PICTURE_PANEL)
            }
        },
        referenceDataNext() {
            this.$v.uicustomer.field1.$touch()
            this.$v.uicustomer.field2.$touch()
            this.$v.uicustomer.field3.$touch()

            if (!this.$v.$anyError) {
                this.nextPanel(this.REFERENCE_PANEL)
            }
        },
        goToCompleteOrder() {
            this.$router.push({ name: 'complete-order' })
        },
        nextPanel(current) {
            // if there are hidden panels, we need to skip them
            const next = this.getNextPanelRef(current, this.LAST_PANEL)

            this.openpanel = next

            if (!this.activepanels.includes(next)) this.activepanels.push(next)

            // close current and open next
            if (this.$refs[current]) this.$refs[current].toggle()
            if (this.$refs[next]) this.$refs[next].toggle()
        },
        getNextPanelRef(current, lastpanel) {
            if (this.$refs[current + 1] !== undefined || current === lastpanel) {
                return current + 1
            } else {
                return this.getNextPanelRef(current + 1, lastpanel)
            }
        },
        accordionClicked(panel) {
            // close all accordions but the clicked one
            Object.keys(this.$refs).forEach(key => {
                const entry = this.$refs[key]
                if (key === panel || (entry && entry.open)) {
                    entry.toggle()
                }
            })

            this.openpanel = panel
        },
        startCropper() {
            this.showcropper = true
        },
        handlePicture(event) {
            this.uicroppedpicture = event
        }
    },
    components: {
        Accordion,
        TextInput,
        DateInput,
        FileUpload,
        UploadCropper,
        TicketInformationContainer,
        CheckboxInput,
        DropdownInput,
    }
}
</script>

<style scoped>
.content-container {
    display: flex;
    flex-wrap: wrap;
}

.content-container > .input-wrapper {
    flex: 1 1 100%;
}

.input-wrapper > .input-container {
    background: #fff;
}

.input-wrapper > .input-container:not(:first-child) {
    margin-top: 2em;
}

.input.header {
    text-align: left;
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
}

.content-container > .information-wrapper {
    min-width: 300px;
    width: 100%;
    margin-top: 2em;
    user-select: none;
}

.information-wrapper > .complete-container {
    flex-direction: column;
    margin-top: 20px;
    height: 75px;
    width: 100%;
    margin-left: 0;
}

.complete-container > label {
    display: block;
}

.complete-container > label:nth-of-type(1) {
    font-weight: bold;
    font-size: 1.5em;
}

.panel-container > .image-container {
    position: relative;
}

.panel-container > .image-container:hover {
    cursor: pointer;
}

.panel-container > .additional-info {
    text-align: left;
}

.panel-container > .image-container.error {
    color: red !important;
}

.image-container.error > .customer-image {
    border-color: red;
    background-color: #ff000011;
}

.image-container.error > .image-placeholder {
    fill: red;
}

.image-container > .customer-image {
    width: 150px;
    height: 225px;
    margin-bottom: 2em;
    background-color: #eee;
    border: 1px solid #999;
    border-radius: 8px;
}

.image-container > .image-placeholder {
    position: absolute;
    height: 50px;
    width: 50px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: calc(50% - 40px);
    fill: #999;
}

.picture-upload >>> .upload-container {
    height: 80%;
}

.panel-container > .image-upload {
    border-radius: 8px;
    height: 40px;
    padding: 5px 10px;
    font-weight: bold;
    align-self: center;
    background-color: #ddd;
}

.panel-container > .next-container {
    margin: 15px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.accordion-container:has(.active.open)::after {
    content: var(--requiredHint);
    margin: 5px 20px;
    display: inline-block;
    text-align: left;
    width: 100%;
}

.sepa-hint {
    text-align: justify;
}

.field-text {
    float: left;
    margin-bottom: 20px;
}

@media(min-width: 576px) {
    .panel-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: row;
    }

    .panel-container.image {
        justify-content: space-around;
    }

    .panel-container > .input {
        width: 49%;
    }

    .panel-container > .input.full {
        width: 100%;
    }
    
    .panel-container > .field-input {
        width: 100%;
    }
}

@media(min-width: 992px) {
    .content-container > .input-wrapper {
        flex: 1;
    }

    .content-container > .information-wrapper {
        min-width: 300px;
        width: 300px;
        margin-top: 0;
        margin-left: 4em;
    }
}
</style>